<script lang="ts" setup>
type ColSize = 'auto' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12'
type ColSplit = `${ColSize}/${ColSize}`
type ColIndex = '1' | '2'
type ColOrder = [ColIndex, ColIndex]

const props = defineProps<{
  split: ColSplit
  mobileOrder?: ColOrder
  order?: ColOrder
}>()

const cols = computed(() => {
  const [left, right] = props.split ? props.split.split('/') : ['6', '6']

  return {
    left,
    right,
  }
})

const computedOrder = computed(() => ({
  mobile: props.mobileOrder ?? ['2', '1'],
  default: props.order ?? ['1', '2'],
}))
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" :md="cols.left" :order="computedOrder.mobile[0]" :order-md="computedOrder.default[0]">
        <slot name="left" />
      </v-col>
      <v-col cols="12" :md="cols.right" :order="computedOrder.mobile[1]" :order-md="computedOrder.default[1]">
        <slot name="right" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass" scoped>
[class^="v-col"]
  margin: auto

.v-container
  max-width: calc(#{$site-width} + #{($base-width * 3 * 2)})
</style>
